.dragdrop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: fit-content;
    row-gap: 2rem;
    column-gap: 4rem;
}

@media only screen and (max-width: 1700px) {
    .dragdrop {
        grid-template-columns: 1fr;
    }
}

